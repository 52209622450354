<template>
  <v-card class="mx-auto" max-width="400">
    <v-img
      class="white--text align-end"
      height="200px"
      src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
    >
      <v-card-title>Ошибка 404</v-card-title>
    </v-img>

    <v-card-subtitle class="pb-0">Упс... страница не найдена</v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'Error404'
};
</script>
